import { createRouter, createWebHistory } from "vue-router";

// import store from '@/store';

const routes = [
	{
		path: '/',
		component: () =>
			import( /* webpackChunkName: "main" */ '../views/__template'),
		children: [{
			path: '',
			component: () =>
				import( /* webpackChunkName: "main" */ '../views/Dashboard'),
		},
		{
			path: '/:catchAll(.*)',
			component: () =>
				import( /* webpackChunkName: "404" */ '../views/404')
		}
		]
	},
	{
		path: '/bedrijfspaginas',
		component: () =>
			import( /* webpackChunkName: "main" */ '../views/companypages/__template'),
		children: [{
			path: '',
			component: () =>
				import( /* webpackChunkName: "main" */ '../views/companypages/Overview'),
		},
		{
			path: 'archief',
			component: () =>
				import( /* webpackChunkName: "main" */ '../views/companypages/Archive'),
		},


		]
	},
	{
		path: '/bedrijfspaginas/:id',
		component: () =>
			import( /* webpackChunkName: "main" */ '../views/companypages/page/__template'),
		children: [
			// 	{
			// 	path: '',
			// 	component: () =>
			// 		import( /* webpackChunkName: "main" */ '../views/companypages/Overview'),
			// },
			{
				path: 'werknemers',
				component: () =>
					import( /* webpackChunkName: "main" */ '../views/companypages/page/EmployeeList'),
			},
			{
				path: 'werknemers/:wid',
				component: () =>
					import( /* webpackChunkName: "main" */ '../views/companypages/page/Employee'),
			},
			{
				path: 'activiteiten',
				component: () =>
					import( /* webpackChunkName: "main" */ '../views/companypages/page/ActivityList'),
			},
			{
				path: 'activiteiten/:aid',
				component: () =>
					import( /* webpackChunkName: "main" */ '../views/companypages/page/Edit'),
			},
		]
	},

	{
		path: "/bedrijfspaginas",
		component: () => import(/* webpackChunkName: "main" */ "../views/companypages/__template"),
		children: [
			{
				path: "",
				component: () => import(/* webpackChunkName: "main" */ "../views/companypages/Overview"),
			},
			{
				path: "archief",
				component: () => import(/* webpackChunkName: "main" */ "../views/companypages/Archive"),
			},
		],
	},
	{
		path: "/bedrijfspaginas/:id",
		component: () => import(/* webpackChunkName: "main" */ "../views/companypages/page/__template"),
		children: [
			// 	{
			// 	path: '',
			// 	component: () =>
			// 		import( /* webpackChunkName: "main" */ '../views/companypages/Overview'),
			// },
			{
				path: "werknemers",
				component: () => import(/* webpackChunkName: "main" */ "../views/companypages/page/EmployeeList"),
			},
			{
				path: "werknemers/:wid",
				component: () => import(/* webpackChunkName: "main" */ "../views/companypages/page/Employee"),
			},
			{
				path: "activiteiten",
				component: () => import(/* webpackChunkName: "main" */ "../views/companypages/page/ActivityList"),
			},
		],
	},


	{
		path: '/activiteiten',
		component: () =>
			import( /* webpackChunkName: "main" */ '../views/activities/__template'),
		children: [{
			path: '',
			component: () =>
				import( /* webpackChunkName: "main" */ '../views/activities/Overview'),
		},
		{
			path: 'archief',
			component: () =>
				import( /* webpackChunkName: "main" */ '../views/activities/Archive'),
		},
		{
			path: ":id/aanmeldingen",
			component: () => import(/* webpackChunkName: "orders" */ "../views/activities/Enrolls"),
		},

		]
	},


	{
		path: '/activiteiten/:id/',
		component: () => import(/* webpackChunkName: "orders" */ '../views/activities/Companyoverview'),
	},
	{
		path: "/bedrijfspaginas/bewerken/:id",
		component: () => import(/* webpackChunkName: "main" */ "../views/companypages/Edit"),
	},

	{
		path: '/activiteiten/company/:id',
		component: () => import(/* webpackChunkName: "orders" */ '../views/activities/Companyoverview'),
	},

	// {
	// 	path: '/activiteiten/aanmaken',
	// 	component: () =>
	// 		import( /* webpackChunkName: "main" */ '../views/activities/Create'),

	// },
	{
		path: '/reviews',
		component: () =>
			import( /* webpackChunkName: "main" */ '../views/reviews/__template'),
		children: [{
			path: '',
			component: () =>
				import( /* webpackChunkName: "main" */ '../views/reviews/Overview'),
		},

		]
	},


	{
		path: '/accountgegevens',
		component: () =>
			import( /* webpackChunkName: "main" */ '../views/account/__template'),
		children: [{
			path: '',
			component: () =>
				import( /* webpackChunkName: "main" */ '../views/account/Overview')
		},


		]
	},
	{
		path: '/instellingen',
		component: () =>
			import( /* webpackChunkName: "main" */ '../views/settings/__template'),
		children: [{
			path: '',
			component: () =>
				import( /* webpackChunkName: "main" */ '../views/settings/Overview'),
		},


		]
	},




	{
		path: '/inloggen',
		component: () =>
			import( /* webpackChunkName: "werkgevers" */ '../views/auth/__template'),
		children: [
			{
				path: "",
				component: () => import(/* webpackChunkName: "main" */ "../views/auth/Login"),
			}
		],
	},

	{
		path: "/link",
		component: () => import(/* webpackChunkName: "link" */ "../views/freelancers/__template"),
		children: [
			{
				path: ":id",
				component: () => import(/* webpackChunkName: "link" */ "../views/freelancers/View"),
			},
		],
	},

	{
		path: "/activiteiten/:id/",
		component: () => import(/* webpackChunkName: "orders" */ "../views/activities/Companyoverview"),
	},
	{
		path: "/activiteiten/:id/bewerken",
		component: () => import(/* webpackChunkName: "orders" */ "../views/activities/Edit"),
	},
	{
		path: "/activiteiten/:id/archief",
		component: () => import(/* webpackChunkName: "orders" */ "../views/activities/Companyarchive"),
	},

	{
		path: "/activiteiten/company/:id",
		component: () => import(/* webpackChunkName: "orders" */ "../views/activities/Companyoverview"),
	},

	// {
	// 	path: "/activiteiten/aanmaken",
	// 	component: () => import(/* webpackChunkName: "main" */ "../views/activities/Create"),
	// },
	{
		path: "/reviews",
		component: () => import(/* webpackChunkName: "main" */ "../views/reviews/__template"),
		children: [
			{
				path: "",
				component: () => import(/* webpackChunkName: "main" */ "../views/reviews/Overview"),
			},
		],
	},

	{
		path: "/accountgegevens",
		component: () => import(/* webpackChunkName: "main" */ "../views/account/__template"),
		children: [
			{
				path: "",
				component: () => import(/* webpackChunkName: "main" */ "../views/account/Overview"),
			},
		],
	},
	{
		path: "/instellingen",
		component: () => import(/* webpackChunkName: "main" */ "../views/settings/__template"),
		children: [
			{
				path: "",
				component: () => import(/* webpackChunkName: "main" */ "../views/settings/Overview"),
			},
		],
	},
	{
		path: "/specialisten",
		component: () => import(/* webpackChunkName: "main" */ "../views/specialists/__template"),
		children: [
			{
				path: "",
				component: () => import(/* webpackChunkName: "main" */ "../views/specialists/Overview"),
			},
		],
	},
	{
		path: "/inloggen",
		component: () => import(/* webpackChunkName: "werkgevers" */ "../views/auth/__template"),
		children: [
			{
				path: "",
				component: () => import("../views/auth/Login"),
			},
		],
	},

	{
		path: "/wachtwoord-vergeten",
		component: () => import(/* webpackChunkName: "werkgevers" */ "../views/auth/__template"),
		children: [
			{
				path: "",
				component: () => import("../views/auth/Resetpassword"),
			},
		],
	},

	{
		path: "/wachtwoord-wijzigen",
		component: () => import(/* webpackChunkName: "werkgevers" */ "../views/auth/__template"),
		children: [
			{
				path: "",
				component: () => import("../views/auth/Newpassword"),
			},
		],
	},

	{
		path: "/registratie-voltooien",
		component: () => import(/* webpackChunkName: "register confirm" */ "../views/auth/__template"),
		children: [
			{
				path: "",
				component: () => import("../views/auth/Registerconfirm"),
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
